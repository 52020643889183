import { toast } from "react-toastify";
import axios from "axios";
import { useEffect, useState } from "react";
import Spinner from "./../_Components/Spinner"
import { validateEmail } from "./../utils"
import { MIN_LENGTH_PASSWORD, TENANT_ID_AMADORI } from "./../consts"
import Root from "../Root";

function Registrazione() {
  const [loading, setLoading] = useState();
  const [userNotExistInCRM, setUserNotExistInCRM] = useState(false);

  const [initValues, setInitValues] = useState({});
  let params = new URL(window.location).searchParams;
  const idUser = params.get("id"); // L'ID è sempre presente 
  const isProgramLoyaltyFinished = true;
  const getData = async () => {

    try {
      setLoading(true)
      const response = await axios.get(`https://v6pz1kddfg.execute-api.eu-south-1.amazonaws.com/prod/api/v1/hubspot/${idUser}`)
      console.log(response.data)
      setInitValues(response?.data)
    } catch (error) {
      setUserNotExistInCRM(true)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData();
  }, [])

  return (
    <>
      <Root/>
      <div className="container">
        {
        (isProgramLoyaltyFinished) ? 
        <p style={{ padding: 25, textAlign: 'center', fontSize: 18, maxWidth:500, margin: "0 auto" }}>
          Spiacenti, l'iniziativa è terminata il <strong>01/12/2024</strong><br/>
          Rimani in contatto con il tuo agente di riferimento per scoprire tutte le <strong>iniziative e promozioni Amadori</strong>
        </p>
        :
          (!idUser || userNotExistInCRM) ? 
            <div style={{ padding: 25, textAlign: 'center', fontSize: 18, maxWidth:500, margin: "0 auto" }}>
                <h4>Collegamento non andato a buon fine.</h4>
                <p style={{margin:0}}>                  
                Il link per la registrazione non è corretto, verifica la mail che hai ricevuto e accertati che termini con: 
                <br/><code className="">"?id=XXXXXXXXX"</code>
                </p>
                <p style={{margin:0, fontSize:12, paddingTop:16}}>Se continui a riscontrare problemi <a href="https://www.amadori.it/contatti" target="_blank">contatta Amadori</a> per ricevere supporto.</p>
            </div>
          :
        
        <form className="mt-5 mx-auto" autoComplete="false" style={{ maxWidth: 550 }} onSubmit={async (e) => {
          e.preventDefault();
          const formData = Object.values(e.target)?.map(f => {
            return ({ name: f.name, value: f.type === "checkbox" ? f.checked : f.value })
          }).filter(f => !!f.name)

          let payload = {}
          formData.forEach(formDataItem => {
            payload[formDataItem.name] = formDataItem.value
          });

          payload = {
            ...payload,
            "tenantId": TENANT_ID_AMADORI,
            "crmId": initValues.id,
            "companyName": "placeholder",
            "privacyAcceptance3": true
          }
          console.log({ payload })
          if (!payload["name"]) {
            toast("Inserisci il nome", {
              type: "warning"
            })
            return
          }
          if (!payload["surname"]) {
            toast("Inserisci il cognome", {
              type: "warning"
            })
            return
          }
          if (!validateEmail(payload["email"])) {
            toast("Inserisci un'indirizzo email valido", {
              type: "warning"
            })
            return
          }
          if (payload["password"]?.length < MIN_LENGTH_PASSWORD) {
            toast(`Inserisci una password valida (almeno ${MIN_LENGTH_PASSWORD} caratteri)`, {
              type: "warning"
            })
            return
          }
          if (payload["password"] !== payload["conferma-password"]) {
            toast("Le due password non coincidono", {
              type: "warning"
            })
            return
          }
          if (!payload["privacyAcceptance1"]) {
            toast("E' necessaria la presa visione del regolamento", {
              type: "warning"
            })
            return
          }

          setLoading(true)
          try {
            await axios.post(`https://api.loyaltyone.io/api/v1/auth/registration`, payload)
            window.location.href = "https://ilcampeseoro.amadori.it";
          } catch (error) {
            toast("Si è verificato un errore, si prega di riprovare più tardi", {
              type: "error"
            })
          } finally {
            setLoading(false)
          }

        }}>
          <div className="form-group">
            <label htmlFor="name">Nome</label>
            <input name="name" className="form-control" id="name" placeholder="Inserisci il nome" />
          </div>

          <div className="form-group">
            <label htmlFor="surname">Cognome</label>
            <input name="surname" className="form-control" id="surname" placeholder="Inserisci il cognome" />
          </div>

          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Indirizzo email</label>
            <input autoComplete="off" defaultValue={initValues?.email} disabled={!!initValues?.email} name="email" type="email" className="form-control"
              id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Inserisci l'email" value={initValues?.email} />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input name="password" type="password" className="form-control" id="password" placeholder="Inserisci la password" />
          </div>

          <div className="form-group">
            <label htmlFor="password-confirm">Conferma password</label>
            <input name="conferma-password" type="password" className="form-control" id="password-confirm" placeholder="Conferma password" />
          </div>

          <div className="form-check">
            <input name="privacyAcceptance1" type="checkbox" className="form-check-input" id="privacyAcceptance1" />
            <label className="form-check-label" htmlFor="privacyAcceptance1">
            Dichiaro di aver letto il <a target="_blank" href="https://ilcampeseoro.amadori.it/regolamento.php">regolamento</a> e di aver preso visione dell'<a href="https://www.iubenda.com/privacy-policy/85851416/legal?from_cookie_policy=true" target="_blank">Informativa Privacy</a> <span className="required">*</span></label>
          </div>

          <div className="form-check">
            <label className="form-check-label">Se non l'hai ancora fatto, iscriviti alla newsletter</label>
          </div>

          <div className="form-check">
            <input name="privacyAcceptance2" type="checkbox" className="form-check-input" id="privacyAcceptance2" />
            <label className="form-check-label" htmlFor="privacyAcceptance2">
              Ho letto l'<a target="_blank" href="https://www.amadori.it/hubfs/CampeseOro_privacy_policy/Privacy%20Policy%20pagina%20web%20Campese%20Oro.pdf">informativa privacy</a> e voglio ricevere comunicazioni da Amadori. Questo consenso è liberamente prestabile e il suo mancato conferimento non arrecherà alcun pregiudizio e non avrà alcuna conseguenza sulla partecipazione all'operazione a premi "Incentive Campese Oro Amadori"
              </label>
            </div>

          <div className="mt-3">
            <button disabled={loading}
              type="submit"
              className={`btn btn-primary w-100`}
              style={{ opacity: loading ? 0.5 : 1 }}
              id="btn-submit">
              {!loading ? "Registrati ora" : <Spinner />}
            </button>
          </div>
        </form>
        }
        {
          !isProgramLoyaltyFinished &&
          <div className="text-center mt-4 text-s">
            <span>{"Password dimenticata?  "}<a href="/password-dimenticata">{"Recupera password"}</a></span>
          </div>
        }
      </div>
    </>

  );
}

export default Registrazione;
